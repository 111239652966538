@import '../../../../scss/theme-bootstrap';

.social-login {
  // Block with `OR` word placed before social-logn
  &__delimeter {
    text-align: center;
    height: 10px;
  }
  &__delimeter--horizontal {
    margin: 20px auto;
    background-color: #{$border-color-normal};
    height: 2px;
    .social-login-title {
      padding: 0 6px;
      background-color: #{$color-white};
      top: -8px;
      position: relative;
      text-transform: uppercase;
      display: inline;
    }
  }
  // Social login block
  &__container {
    .gnav-account & {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .social-network {
      margin: 10px 0;
      padding: 0;
      display: block;
      height: 48px;
      overflow: hidden;
      border: none;
      position: relative;
      &__button {
        width: 100%;
        height: 100%;
        display: block;
        letter-spacing: 0.25px;
        border: none;
        line-height: 48px;
        color: #{$color-white};
        &--label {
          @include swap_direction(padding, 0 0 0 40px);
          text-align: center;
          display: inline-block;
          width: 100%;
          .gnav-account & {
            display: none;
          }
        }
      }
      &__blocker {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
        &-hidden {
          display: none;
        }
      }
      .gnav-account & {
        margin: 10px;
        width: 20%;
      }
    }
    .legal-container {
      &__acceptance {
        &-error {
          color: #{$color-error};
          a {
            color: #{$color-error};
          }
        }
      }
    }
  }
  @include breakpoint($landscape-up) {
    &__delimeter--horizontal {
      width: 69%;
    }
  }
}
